<template>
  <div class="main-top">
    <div class="left"></div>
    <div class="right">
      <div class="shouye" v-if="isShow" @click="toIndex">
        <img src="../../assets/images/a6.svg" alt="" />
        <div
          style="
            margin-top: 5px;
            margin-left: 3px;
            font-size: 18px;
            color: #b1cdf6;
          ">
          首页
        </div>
      </div>
      <div class="the-data">
        <div class="the-time">{{ dateDay }}</div>
        <div class="the-year">{{ dateYear }}</div>
      </div>
      <!-- <div class="the-weather">
        <img src="../../assets/weather.svg" alt="" />
        <div class="weather-data">
          <div class="the-word">晴转多云</div>
          <div class="the-num">26~28*C</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { formatTime } from '../../utils/index.js'

export default {
  components: {},
  props: {
    isShow: {
      type: Boolean,
      // required: true,
      default: false
    }
  },
  data() {
    return {
      timing: null,
      dateDay: null,
      dateYear: null,
      address: '中国安能一局合肥分公司'
      // dateWeek: null,
      // weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
    }
  },

  mounted() {
    this.timeFn()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy.MM.dd')
        // this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    toIndex() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-top {
  display: flex;
  justify-content: space-between;
  background-image: url('../../assets/images/top.svg');
  height: 96px;
  .left {
    display: flex;
    align-items: center;
    .address {
      height: 26px;
      font-size: 24px;
      line-height: 26px;
      // color: #fff;
      padding-right: 12px;
      border-right: 2px solid #fff;
      margin-right: 15px;
      font-weight: 600;
      background-image: -webkit-linear-gradient(bottom, #f9fbfe, #afe4fe);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .descript {
      color: #def3fe;
      font-size: 14px;
    }
  }
  .right {
    display: flex;
    margin-top: 15px;
    // align-items: center;
    padding-right: 30px;
    .the-data {
      width: 126px;
      color: #b1cdf6;
      font-style: italic;
      // padding-right: 40px;
      // border-right: 2px solid #435b76;
      text-align: right;
      padding-top: 3px;
      .the-time {
        font-size: 20px;
        margin-bottom: 1px;
      }
      .the-year {
        font-size: 12px;
      }
    }
    .the-weather {
      display: flex;
      padding-left: 30px;
      font-size: 18px;

      .weather-data {
        margin-left: 20px;
        color: #b1cdf6;
        font-size: 14px;
        text-align: right;
        .the-num {
          font-size: 18px;
        }
      }
    }
  }
}
.shouye {
  // & > img {
  //   margin-top: 3px;
  // }
  height: 30px;
  margin-top: 3px;
  display: flex;
  align-items: flex-start;
  border-right: 2px solid #435b76;
  padding-right: 30px;
  cursor: pointer;
}
</style>
