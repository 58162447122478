import axios from 'axios'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://192.168.43.5:8080/api',

  // baseURL: 'http://192.168.232.70:8080/api',
  // baseURL: 'http://192.168.232.111:8080/api',
  baseURL: 'http://101.43.127.134:8080/api',
  // 超时
  timeout: 60000
})
export default service
