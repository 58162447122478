import request from '@/utils/request'

export function anUserStatistic(data) {
  return request({
    url: '/anneng/user/getAnUserStatistic',
    method: 'get',
    params: data
  })
}
export function all(data) {
  return request({
    url: '/anneng/user/getAll',
    method: 'get',
    params: data
  })
}
export function classify(data) {
  return request({
    url: '/anneng/user/getClassify',
    method: 'get',
    params: data
  })
}
export function userDeptName(data) {
  return request({
    url: '/anneng/user/getUserDeptName',
    method: 'get',
    params: data
  })
}
export function equipTotal(data) {
  return request({
    url: '/anneng/equip/getEquipTotal',
    method: 'get',
    params: data
  })
}
export function equipDetail(data) {
  return request({
    url: '/anneng/equip/getEquipDetail',
    method: 'get',
    params: data
  })
}
export function materialTota(data) {
  return request({
    url: '/anneng/material/getMaterialTotal',
    method: 'get',
    params: data
  })
}
export function materialDetail(data) {
  return request({
    url: '/anneng/material/geMaterialDetail',
    method: 'get',
    params: data
  })
}
export function mapPoint(data) {
  return request({
    url: '/anneng/map/getMapPoint',
    method: 'get',
    params: data
  })
}
export function mapPointDetail(data) {
  return request({
    url: '/anneng/map/getMapPointDetail',
    method: 'get',
    params: data
  })
}
export function rescueInfo(data) {
  return request({
    url: '/anneng/map/getRescueInfo',
    method: 'get',
    params: data
  })
}
export function rescueUserGroupInfo(data) {
  return request({
    url: 'anneng/map/getRescueUserGroupInfo',
    method: 'get',
    params: data
  })
}
export function rescueUserDetailInfo(data) {
  return request({
    url: 'anneng/map/getRescueUserDetailInfo',
    method: 'get',
    params: data
  })
}
export function userMapPoint(data) {
  return request({
    url: 'anneng/map/getUserMapPoint',
    method: 'get',
    params: data
  })
}
export function pointUserDetail(data) {
  return request({
    url: 'anneng/map/getPointUserDetail',
    method: 'get',
    params: data
  })
}
export function teamEquipments(data) {
  return request({
    url: 'anneng/map/getTeamEquipments',
    method: 'get',
    params: data
  })
}
export function teamMaterials(data) {
  return request({
    url: 'anneng/map/getTeamMaterials',
    method: 'get',
    params: data
  })
}

// 查询现场指挥所和处置组
export function organInfo(query) {
  return request({
    url: '/anneng/map/getTeamCommandGroups',
    method: 'get',
    params: query
  })
}
// 人员
export function anUserInfo(query) {
  return request({
    url: '/anneng/user/getAnUserInfo',
    method: 'get',
    params: query
  })
}
// 人员列表
export function anUserList(query) {
  return request({
    url: '/anneng/user/getAnUserList',
    method: 'get',
    params: query
  })
}
// 人员分类
export function queryUserType(query) {
  return request({
    url: '/anneng/user/getUserType',
    method: 'get',
    params: query
  })
}
// 人员工种
export function queryUserJob(query) {
  return request({
    url: '/anneng/user/getUserJob',
    method: 'get',
    params: query
  })
}
// 装备
export function equipInfo(query) {
  return request({
    url: '/anneng/equip/getEquipInfo',
    method: 'get',
    params: query
  })
}
// 物资
export function eMaterialInfo(query) {
  return request({
    url: '/anneng/material/getMaterialInfo',
    method: 'get',
    params: query
  })
}
// 分类
export function userType(query) {
  return request({
    url: '/anneng/user/getUserType',
    method: 'get',
    params: query
  })
}
// 工种
export function userJob(query) {
  return request({
    url: 'anneng/user/getUserJob',
    method: 'get',
    params: query
  })
}
// bumen下拉
export function equipDeptName(query) {
  return request({
    url: 'anneng/equip/getEquipDeptName',
    method: 'get',
    params: query
  })
}
// 大类下拉
export function equipLargeType(query) {
  return request({
    url: 'anneng/equip/getEquipLargeType',
    method: 'get',
    params: query
  })
}
// 中类下拉
export function equipMeduimType(query) {
  return request({
    url: 'anneng/equip/getEquipMediumType',
    method: 'get',
    params: query
  })
}
// 小类下拉
export function equipSmallType(query) {
  return request({
    url: 'anneng/equip/getEquipSmallType',
    method: 'get',
    params: query
  })
}
// 装备列表
export function equipList(query) {
  return request({
    url: 'anneng/equip/equipList',
    method: 'get',
    params: query
  })
}
// 部门下拉
export function materialDeptName(query) {
  return request({
    url: 'anneng/material/getMaterialDeptName',
    method: 'get',
    params: query
  })
}
// 大类下拉
export function materialLargeType(query) {
  return request({
    url: 'anneng/material/getMaterialLargeType',
    method: 'get',
    params: query
  })
}
// 中类下拉
export function materialMediumType(query) {
  return request({
    url: 'anneng/material/getMaterialMediumType',
    method: 'get',
    params: query
  })
}
// // 小类下拉
// export function materialSmallType(query) {
//   return request({
//     url: 'anneng/material/getMaterialSmallType',
//     method: 'get',
//     params: query
//   })
// }
// 装备信息查询
export function materialList(query) {
  return request({
    url: 'anneng/material/materialList',
    method: 'get',
    params: query
  })
}

// 小类下拉
export function warninglist(query) {
  return request({
    url: 'anneng/vehicle/warninglist',
    method: 'get',
    params: query
  })
}
